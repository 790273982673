import { useEffect, useState } from 'react'
import heritageService from '../../../services/heritage.service'
import { columns } from './table/columns'
import { Flex, Spin, message } from 'antd'
import { userMessages } from '../../../utils/userMessages'
import PaginationTable from '../../../components/PaginationTable'
import { extractAndConvertValues } from '../../../utils/helpers'

export default function HeritageContent() {
  const [tableProps, setTableProps] = useState({
    title: 'Heritage Content',
    entity: 'heritage content',
    columns: columns,
    actions: ['edit', 'remove','map'],
    hasRowSelection: false,
    defaultFilter: {},
    data: []
  })
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  })

  const [loading, setLoading] = useState(false)

  const handleSearch = async (searchTerm) => {
    getHeritageContent(1, 10, searchTerm)
  }

  const getHeritageContent = async (page = 1, pageSize = 10, filters = tableProps.defaultFilter) => {
    const offset = (page - 1) * pageSize
    const limit = pageSize
    let newHeritageList = []
    const filter = extractAndConvertValues(filters)
    try {
      setLoading(true)
      const heritageResponse = await heritageService.getAllHeritageContent(limit, offset, filter)
      newHeritageList = heritageResponse.result

      setPagination((prevState) => ({
        ...prevState,
        currentPage: page,
        pageSize: pageSize,
        total: heritageResponse.totalCount
      }))
      setTableProps((prevState) => ({
        ...prevState,
        data: [...newHeritageList],
        defaultFilter: filters
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteHeritageContent = async (heritage) => {
    try {
      setLoading(true); 
      await heritageService.deleteHeritageContentById(heritage.id);
      message.success(userMessages.deleted);
      await getHeritageContent(pagination.currentPage, pagination.pageSize);
    } catch (error) {
      message.error("Error al eliminar el contenido.");
      console.error(error);
    } finally {
      setLoading(false); 
    }
  };

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'creation_date')
  }

  useEffect(() => {
    getHeritageContent(pagination.currentPage, pagination.pageSize)

    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns
      }))
    }
  }

  return (
    <div className="content">
      {loading ? (
        <Flex align="center" gap="middle" justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <PaginationTable
          props={{
            ...tableProps,
            onAdd: () => getHeritageContent(pagination.currentPage, pagination.pageSize),
            onUpdate: () => getHeritageContent(pagination.currentPage, pagination.pageSize),
            onDelete: deleteHeritageContent,
            pagination: pagination,
            onPaginationChange: async (page, pageSize) => {
              await getHeritageContent(page, pageSize)
            },
            onSearch: handleSearch
          }}
        />
      )}
    </div>
  )
}
