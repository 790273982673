import { useEffect, useState } from 'react'
import ambassadorService from '../../services/ambassador.service'
import { Flex, Spin, message } from 'antd'
import { userMessages } from '../../utils/userMessages'
import { columns } from './table/columns'
import PaginationTable from '../../components/PaginationTable'
import { extractAndConvertValues } from '../../utils/helpers'
export default function Ambassadors() {
  
  const [tableProps, setTableProps] = useState({
    title: 'Ambassadors',
    entity: 'ambassador',
    columns: columns,
    actions: [],
    hasRowSelection: false,
    defaultFilter: {},
    data: []
  })
  const [loading, setLoading] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  })

  const getAmbassadors = async (page = 1, pageSize = 10, filters = tableProps.defaultFilter) => {
    const offset = (page - 1) * pageSize
    const limit = pageSize
    let newAmbassadors = []
    const filter = extractAndConvertValues(filters)
    try {
      setLoading(true)
      const ambassadors = await ambassadorService.getAllAmbassadors(limit, offset, filter)
      newAmbassadors = ambassadors?.result?.map((item) => ({
        ...item,
        country_name: item.country?.name,
        state_name: item.state?.name
      }))
      setPagination((prevState) => ({
        ...prevState,
        currentPage: page,
        pageSize: pageSize,
        total: ambassadors.totalCount
      }))
      setTableProps((prevState) => ({
        ...prevState,
        data: newAmbassadors,
        defaultFilter: filters
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async (searchTerm) => {
    getAmbassadors(1, 10, searchTerm)
  }

  const deleteAmbassador = async (ambassador) => {
    await ambassadorService.deleteAmbassadorById(ambassador.id)
    message.success(userMessages.deleted)
    getAmbassadors(pagination.currentPage, pagination.pageSize)
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'country')
  }

  useEffect(() => {
    getAmbassadors(pagination.currentPage, pagination.pageSize)

    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns
      }))
    }
  }

  return (
    <div className="content">
      {loading ? (
        <Flex align="center" gap="middle" justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <PaginationTable
          key={tableProps.actions.join('-')}
          props={{
            ...tableProps,
            onAdd: () => getAmbassadors(pagination.currentPage, pagination.pageSize),
            onUpdate: () => getAmbassadors(pagination.currentPage, pagination.pageSize),
            onDelete: deleteAmbassador,
            pagination: pagination,
            onPaginationChange: async (page, pageSize) => {
              await getAmbassadors(page, pageSize)
            },
            onSearch: handleSearch
          }}
        />
      )}
    </div>
  )
}
