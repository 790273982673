import React, { useEffect, useState, useRef } from 'react'
import { Col, Row, Space, Table, theme, Tooltip, Typography, Modal, Button, Breadcrumb, DatePicker, Input, Pagination, Select } from 'antd'
import { SettingOutlined, PlusOutlined, SearchOutlined, CalendarOutlined } from '@ant-design/icons'
import RemoveButton from './RemoveButton'
import EditButton from './EditButton'
import ContentButton from './ContentButton'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs, useBreadcrumbsUpdate } from '../../Hooks/useBreadcrumbs'
import { useLocation } from 'react-router-dom'
import UpdateItemForm from '../UpdateItemForm'
import HistoryListButton from './HistoryListButton'
import CalendarButton from './CalendarButton'
import Highlighter from 'react-highlight-words'
import { CSVLink } from 'react-csv'
import UserForm from '../../pages/Users/components/userForm.component'
import AmbassadorForm from '../../pages/Ambassadors/components/ambassadorForm.component'
import HeritageForm from '../../pages/Heritage/Heritage/components/heritageForm.component.js'
import HeritageContentForm from '../../pages/Heritage/HeritageContent/components/heritageContentForm.component.js'
import RoadRouteForm from '../../pages/Road/RoadRoute/components/roadRouteForm.component'
import HeritageProposalForm from '../../pages/Heritage/HeritageProposal/components/heritageProposalForm.component.js'
import StateForm from '../../pages/Cities/States/components/stateForm.component.js'
import CountryForm from '../../pages/Cities/Countries/components/countriesForm.component.js'
import CitysForm from '../../pages/Cities/Cities/components/cityForm.component.js'
import OcityForm from '../../pages/Cities/Ocity/components/ocityForm.component.js'
import './index.css'
import { Option } from 'antd/es/mentions/index.js'
import noAvailableImage from '../../assets/NO_AVAILABLE_IMG.png'
import SearchBar from './SearchBar/SearchBar.jsx'
import InfoButton from './InfoButton/index.jsx'
import SeeInTheMapButton from './SeeInTheMapButton/index.jsx'


import { useAuth } from '../../Hooks'
import { checkPrivileges } from '../../utils/tablePrivileges.js'

const { useToken } = theme
const { Title } = Typography


const PaginationTable = ({ props }) => {
  const elementInputAttributes = props.inputAttributes
  const onAdd = props.onAdd
  const onDelete = props.onDelete
  const onPaginationChange = props.onPaginationChange
  const onUpdate = props.onUpdate
  const pagination = props.pagination ?? {
    currentPage: 1,
    pageSize: 10,
    total: 0
  }
  const defaultSearchKey = props.defaultSearchKey ? props.defaultSearchKey : 'name'
  const selectionType = 'checkbox'
  const tableCols = props.columns 
  const tableHasRowSelection = props.hasRowSelection
  const tableTitle = props.title
  const defaultFilter = props?.defaultFilter

  const { token } = useToken()
  const { t } = useTranslation()
  const location = useLocation()

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)

  const updateBreadcrumbs = useBreadcrumbsUpdate()
  const breadcrumbItems = useBreadcrumbs()

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const [tableData, setTableData] = useState([])

  const searchInput = useRef(null)

  const { user } = useAuth()

  if (!user || !user.roles) {
    return <></>
  }

  const tableActions = checkPrivileges(user.roles, tableTitle )

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name
    })
  }

  /********************************************************
                        FUNCTIONS
   ********************************************************/
  // PAGINATION
  // Segment the data based on current page and page size
  // MODAL
  const openAddModal = () => {
    setIsAddModalOpen(true)
  }

  const closeAddModal = () => setIsAddModalOpen(false)

  const handleAddCancel = () => {
    setIsAddModalOpen(false)
  }

  // ----------------------------------------------------------------------
  // TABLE ON CHANGE
  // ----------------------------------------------------------------------
  const onTableChange = (pagination, filters) => {
    props.onSearch(filters)
  }

  // ----------------------------------------------------------------------
  // DATE PICKER AND FILTERS
  // ----------------------------------------------------------------------
  const getDaysArray = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt))
    }
    return arr
  }

  const getColumnDateFilterProps = (dataIndex) => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <>
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onChange={(value) => {
            clearFilters()
            let dates = getDaysArray(value?.[0]?.$d, value[1]?.$d)
            setSelectedKeys(dates)
          }}
          onOk={(value) => {
            let dates = getDaysArray(value?.[0]?.$d, value[1]?.$d)
            setSelectedKeys(dates)
          }}
        />
        <Button
          type="primary"
          onClick={() => confirm() && handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90
          }}
        />
      </>
    ),
    filterIcon: () => <CalendarOutlined />
  })

  const getColumnDropdownFilterProps = (dataIndex) => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Select type"
          value={selectedKeys?.[0]}
          onChange={(value) => {
            setSelectedKeys([
              {
                value: value,
                label: value
              }
            ])
          }}
        >
          <Option value="HeritageGroup">HeritageGroup</Option>
          <Option value="Route">Route</Option>
        </Select>
        <br />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              margin: '5px'
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch(selectedKeys, confirm, dataIndex, false)
            }}
            size="small"
            style={{
              width: 90,
              margin: '5px'
            }}
          >
            Reset
          </Button>
        </Space>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close()
          }}
        >
          close
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    defaultFilteredValue: defaultFilter?.type ?? defaultFilter?.type?.[0]
  })

  const handleSearch = async (selectedKeys, confirm, dataIndex, closeDropdown = true) => {
    confirm({
      closeDropdown
    })
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              handleSearch(selectedKeys, confirm, dataIndex, false)
            }}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    defaultFilteredValue: defaultFilter ? defaultFilter[dataIndex] : '',

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  // ----------------------------------------------------------------------
  // ACTIONS AND COLUMNS
  // ----------------------------------------------------------------------
  const drawActions = (data) => {
    //console.log("TABLE ACTIONS", tableActions)
    let tempActions = []
    if (tableActions.includes('content'))
      tempActions.push(
        <ContentButton
          inputAttributes={elementInputAttributes}
          element={data}
          entity={props.entity}
          openModal={openAddModal}
        />
      )
    if (tableActions.includes('edit')){

    console.log("TABLE EDIT", tableActions)
    tempActions.push(
        <EditButton
        inputAttributes={elementInputAttributes}
        element={data}
        entity={props.entity}
        openModal={openAddModal}
        onUpdate={onUpdate}
        />
      )
    }
    if (tableActions.includes('info'))
      tempActions.push(
        <InfoButton
          inputAttributes={elementInputAttributes}
          element={data}
          entity={props.entity}
          openModal={openAddModal}
        />
      )
    if (tableActions.includes('calendar')) tempActions.push(<CalendarButton element={data} />)
    if (tableActions.includes('history')) tempActions.push(<HistoryListButton element={data} />)
    if (tableActions.includes('map')) tempActions.push(<SeeInTheMapButton entity={props.entity} element={data} />)
    if (tableActions.includes('remove')) tempActions.push(<RemoveButton element={data} onDelete={onDelete} />)
    return <Space>{tempActions}</Space>
  }

  const getPropertyValue = (item, property) => {
    let value = item

    if (property.includes('.')) {
      const properties = property.split('.')
      for (const property of properties) {
        if (!value) return ''
        value = value[property]
      }
    } else {
      value = item[property]
    }
    return value
  }

  const getDropdownValue = (item) => {
    let value = item?.tags?.length > 0 ? 'HeritageGroup' : 'Route'
    return value
  }

  const getColumns = () => {
    const temp = []

    tableCols.forEach((column) => {
      /**
       * Create FILTERS for columns
       */
      let filters = []
      let elementsFiltered = []
      for (let i = 0; i < tableData?.length; i++) {
        const value = getPropertyValue(tableData[i], column.key)
        if (!elementsFiltered.includes(value)) {
          filters.push({
            text: value,
            value: value
          })
        }
        elementsFiltered.push(value)
      }
      // normal column with name filter
      if (column.filter === 'name') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          filters: filters,
          onFilter: (value, record) => record[column.key].indexOf(value) === 0
        })
      }
      // normal column with number filter
      else if (column.filter === 'number') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          sorter: (a, b) => a[column.key] - b[column.key],
          render: (_, item) => getPropertyValue(item, column.key)
        })
      }
      // normal column with text filter
      else if (column.filter === 'text') {
        temp.push({
          ...getColumnSearchProps(column.key),
          title: t(column.name),
          dataIndex: column.key,
          render: (_, item) => getPropertyValue(item, column.key)
        })
      }
      // normal column with date filter
      else if (column.filter === 'date') {
        temp.push({
          ...getColumnDateFilterProps(),
          title: t(column.name),
          dataIndex: column.key,
          render: (_, item) => getPropertyValue(item, column.key)
        })
      }
      // normal column with boolean filter
      else if (column.filter === 'boolean') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          render: (text) => <>{text ? t('Yes') : t('No')}</>,
          sorter: (a, b) => a[column.key] - b[column.key]
        })
      } else if (column.filter === 'image') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          render: (text) => (
            <img
              src={text}
              alt={text}
              onError={(event) => {
                event.target.src = noAvailableImage
              }}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                borderRadius: '50%'
              }}
            />
          )
        })
      }
      // normal column without filter
      else if (column.filter === 'none' || !column.filter) {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          render: (_, item) => getPropertyValue(item, column.key)
        })
      } else if (column.filter === 'dropdown') {
        temp.push({
          ...getColumnDropdownFilterProps(column?.key),
          title: t(column.name),
          dataIndex: column.key,
          render: (_, item) => getDropdownValue(item)
        })
      }
    })

    if (tableActions?.length > 0) {
      temp.push({
        title: <SettingOutlined style={{ fontSize: 'large' }} />,
        dataIndex: 'actions'
      })

      for (let i = 0; i < tableData?.length; i++) {
        tableData[i].actions = drawActions(tableData[i])
      }
    }

    return temp
  }
  const tableColumns = getColumns()

  // ----------------------------------------------------------------------
  // EXPORT TO CSV
  // ----------------------------------------------------------------------
  const prepareExport = (cols, data) => {
    const csvData = []
    if (data?.length > 0) {
      let titleRow = []
      for (let i = 0; i < cols?.length; i++) {
        if (cols[i].dataIndex !== 'actions') {
          titleRow.push(cols[i].title)
        }
      }
      csvData.push(titleRow)
      for (let i = 0; i < data?.length; i++) {
        let row = []
        for (let x = 0; x < cols.length; x++) {
          if (cols[x].dataIndex !== 'actions') {
            row.push(data[i][cols[x].dataIndex])
          }
        }
        csvData.push(row)
      }
    }
    return csvData
  }
  const csvData = prepareExport(tableColumns, tableData)

  useEffect(() => {
    setTableData(props.data)
  }, [props.data])

  useEffect(() => {
    updateBreadcrumbs(location)
  }, [location, updateBreadcrumbs])

  const renderForm = (entity) => {
    let form
    switch (entity) {
      case 'user': {
        form = (
          <UserForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'ocity': {
        form = (
          <OcityForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'city': {
        form = (
          <CitysForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'state': {
        form = (
          <StateForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'country': {
        form = (
          <CountryForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'ambassador': {
        form = (
          <AmbassadorForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'heritage': {
        form = (
          <HeritageForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'heritage proposal': {
        form = (
          <HeritageProposalForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'heritage content': {
        form = (
          <HeritageContentForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      case 'road route': {
        form = (
          <RoadRouteForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd?.()
              closeAddModal()
            }}
          />
        )
        break
      }

      default: {
        form = <UpdateItemForm inputAttributes={elementInputAttributes} />
        break
      }
    }

    return form
  }

  return (
    <>
      <Row align="middle" justify="space-between">
        <Col>
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          <Title style={{ color: token.colorText }}>{t(tableTitle)}</Title>
        </Col>
        <Col span={12}>
          <SearchBar
            onSearch={(value) => {
              props.onSearch({ [defaultSearchKey]: value })
            }}
          />
        </Col>
        <Col>
          <Space>
            {
              tableActions.includes('csv') ?
              <CSVLink filename={'Users_table.csv'} data={csvData} className="btn btn-primary">
                {t('Export to CSV')}
              </CSVLink> : 
              <></>
            }
            <Button>{t('Back')}</Button>
            {
              tableActions.includes('create') ?
              <Tooltip title={t('New') + ' ' + t(tableTitle)}>
                <Button
                  type="primary"
                  shape="circle"
                  className="buttonCentered"
                  icon={<PlusOutlined />}
                  onClick={openAddModal}
                  />
              </Tooltip> : <></>
            
            }

            <Modal width={800} footer={null} title={t('Add Element')} open={isAddModalOpen} onCancel={handleAddCancel}>
              {renderForm(props.entity)}
            </Modal>
          </Space>
        </Col>
      </Row>

      <Table
        dataSource={tableData}
        columns={tableColumns}
        rowSelection={ tableHasRowSelection ? { type: selectionType, ...rowSelection }: false }
        rowClassName={(record) => {
          if (record.is_verified != undefined && record.is_verified != null)
            return record.is_verified ? '' : 'highlight-row'
          else return ''
        }}
        onChange={onTableChange}
        pagination={false}
      />
      <Pagination
        current={pagination.currentPage}
        pageSize={pagination.pageSize}
        total={pagination.total ? pagination.total : tableData?.length}
        onChange={onPaginationChange}
        style={{ marginTop: '20px', textAlign: 'right' }}
      />
    </>
  )
}

export default PaginationTable
