import http from '../http-common'

export async function uploadFileInChunks(file, uploadUrl, chunkSize = 50 * 1024 * 1024) {
  const totalChunks = Math.ceil(file.size / chunkSize);
  const fileId = `${Date.now()}-${file.name}`; // Unique file ID

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('fileId', fileId);
    formData.append('chunkIndex', chunkIndex);
    formData.append('totalChunks', totalChunks);
    formData.append('path', 'heritage/content');
    formData.append('mimetype', file.type);

    console.log(`Uploading chunk ${chunkIndex + 1} of ${totalChunks}`);

    try {
      const response = await http.post(uploadUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.status !== 200) {
        throw new Error(`Chunk ${chunkIndex + 1} upload failed with status ${response.status}`);
      }

      // Check if this is the last chunk and return the URL
      if (chunkIndex + 1 === totalChunks && response.data.fileUrl) {
        return response.data.fileUrl; // Return the S3 URL
      }
    } catch (error) {
      console.error(`Error uploading chunk ${chunkIndex + 1}:`, error);
      return; // Stop further uploads if a chunk fails
    }
  }
}
