import { Form, Select,Alert } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cityService } from '../../services/city.service'
import { cityOcityService } from '../../services/city_ocity.service'

const DropdownCities = ({ state_id, value = null, disabled = null, onChange, error, originEntity="",showError = true, byState=true, required=true }) => {
  const { t } = useTranslation()
  const [citiesList, setCitiesList] = useState([])
  const [cityId, setCityId] = useState(null)
  const [warningThisCityIsNotAvailable,setWarningThisCityIsNotAvailable] = useState({error:false,message:""})

  useEffect(() => {
    if (state_id || byState) {
      cityService.getCitiesByStateId(state_id).then((list)=>{
        setCitiesList(list.map((item) => ({ label: item.name, value: item.id })))
      })
    }
    else {
      cityService.getCities(state_id).then((list)=>{
        setCitiesList(list.result.map((item) => ({ label: item.name, value: item.id })))
      })
    }
  }, [state_id,byState])

  useEffect(() =>{
    if( originEntity == 'users' || originEntity == 'heritage_proposal'){
      onChange(value, true)
      return
    }
    if (cityId) {
        cityOcityService.getCityByCityId(cityId).then((cityAvailable)=>{
          // si estamos en o-city cities, esta ciudad ya ha sido creada la vamos a repetir. 
          // Mostrar que ya esta creada. No permitir crear una nueva
          if(cityAvailable && originEntity == 'city_ocity' ){
            throw new Error("This city is already created.")
          }
          setWarningThisCityIsNotAvailable({error:false,message:""})
          onChange(value, true)
        }).catch((error) => {
          // Vamos a crear un heritage en una ciudad ocity que no existe, 
          // deberiamos de crearla antes de seleccionarla. Y no permitir crear heritages
          if( error?.response?.status == 404){
             if(originEntity == 'heritage'){
              setWarningThisCityIsNotAvailable({error:true,message:t('Error this city is not created in O-CITY. Please go to city O-CITY menu and create this new city.')})
              onChange(value, false)
              return
            } 
            // Vamos a crear una ciudad ocity y esta no existe asi que es la primera vez que la creamos
            // Debemos de permitir crear la entidad 
            if( originEntity == 'city_ocity'){
              setWarningThisCityIsNotAvailable({error:false,message:""})
              onChange(value, true)
              return
            }
          }
          setWarningThisCityIsNotAvailable({error:true,message:error.message})
          onChange(value, false)
      })

    }
  }, [cityId])

const changeValue = (value,option)=>{
  onChange(value, false, option)

  setCityId(value)
}

  return (
    <Form.Item required={required} label={t('City')} className={{ 'input-error': error }}>
      <>
        <Select id="city_id" name="city_id"options={citiesList}optionFilterProp="children" placeholder="Choose a City"
          showSearch disabled={disabled}  style={{ width: '100%' }}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
          value={value}
          onChange={changeValue}
          />
            { warningThisCityIsNotAvailable.error && showError &&
              <Alert  style={{margin:'8px'}} message={warningThisCityIsNotAvailable.message} type="error" showIcon />
            }
        {error && (
          <p className="invalid-feedback d-block" style={{ fontSize: '14px' }}>
            {error}
          </p>
        )}
      </>
    </Form.Item>
  )
}

export default DropdownCities
