import { AimOutlined } from '@ant-design/icons'
import { theme } from 'antd'
import { useNavigate } from 'react-router-dom'

const { useToken } = theme

function SeeInTheMapButton({ element, entity }) {
  const navigate = useNavigate()
  const { token } = useToken()
  function openInMap() {
     if(element.heritage?.city_id){
      element.city_id = element.heritage.city_id
     }

    if (entity == 'ocity') navigate(`/city/${element.city_id}`)
    if (entity == 'heritage') navigate(`/city/${element.city_id  }/heritage/${element.id}`)
    if (entity == 'heritage content') navigate(`/city/${element.city_id  }/heritage/${element.heritage_id}`)
  }

  return <AimOutlined style={{
    fontSize: 'large', 
    color: element.is_verified ? token.colorPrimary : 'gray', 
    cursor: element.is_verified ? 'pointer' : 'not-allowed'
  }} 
  onClick={element.is_verified ? openInMap : undefined} />
}

export default SeeInTheMapButton
