import { ROLES } from "./constants";

function hasRoleAtMost(num, array) {
    return array.some(item => item.role_id <= num);
  }
  
  export const checkPrivileges = (userRoles, entity) => {
    let actions = []
    switch(entity){
        case 'Ambassadors' : 
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
            actions.push(...['edit', 'create', 'info'])
            }
            if( hasRoleAtMost(ROLES.FOCAL_POINT, userRoles) ){
            actions.push(...['remove', 'csv'])
            }
            break;
        // GEO --------------------------
        case 'Cities' : 
            if( hasRoleAtMost(ROLES.SCHOOLING_ALLY, userRoles) ){
            actions.push(...['edit', 'create', 'info'])
            }
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
            actions.push(...['remove','csv'])
            }
            break;
        case 'States' : 
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
                actions.push(...['edit', 'create', 'info','remove','csv'])
            }
            break;
        case 'Countries' : 
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
                actions.push(...['edit', 'create', 'info','remove','csv'])
            }
            break;
        // -----------------------------
        case 'Cities Ocity' : 
            if( hasRoleAtMost(ROLES.SCHOOLING_ALLY, userRoles) ){
            actions.push(...['edit', 'create', 'info'])
            }
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
            actions.push(...['remove','csv'])
            }
            break;
        case 'Heritage' : 
            actions.push(...['map'])
            if( hasRoleAtMost(ROLES.ACTIVIST, userRoles) ){
            actions.push(...['edit', 'create', 'info'])
            }
            if( hasRoleAtMost(ROLES.SCHOOLING_ALLY, userRoles) ){
            actions.push(...['remove'])
            }
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
                actions.push(...['csv'])
            }
            break;
        case 'Heritage Content' : 
            actions.push(...['map'])
            if( hasRoleAtMost(ROLES.ACTIVIST, userRoles) ){
            actions.push(...['edit', 'create', 'info'])
            }
            if( hasRoleAtMost(ROLES.SCHOOLING_ALLY, userRoles) ){
            actions.push(...['remove'])
            }
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
                actions.push(...['csv'])
            }
            break;
        case 'Heritage Proposal' : 
            if( hasRoleAtMost(ROLES.ACTIVIST, userRoles) ){
            actions.push(...['create'])
            }
            break;
        case 'Users' : 
            if( hasRoleAtMost(ROLES.SUPER_ADMIN, userRoles) ){
            actions.push(...['create','edit','remove'])
            }
            break;
        case 'Road Route' : 
            if( hasRoleAtMost(ROLES.CITIZENS, userRoles) ){
            actions.push(...['create'])
            }
            if( hasRoleAtMost(ROLES.SCHOOLING_ALLY, userRoles) ){
                actions.push(...['edit','info'])
            }
            if( hasRoleAtMost(ROLES.AMBASSADOR, userRoles) ){
                actions.push(...['remove', 'csv'])
        }
        break;
    }
    return actions
  }
  