import http from '../http-common'
import { ENV } from '../utils'

class CountryService {
  async getCountries(limit, offset, filters) {
    const { data } = await http.post(
      `/api/${ENV.API_ROUTES.COUNTRY}?limit=${limit ?? 0}&offset=${offset ?? 0}`,
      filters
    )
    data.result = data.result.map(country => {
      if (country.created_at) {
        country.created_at = country.created_at.split('T')[0];
      }
      return country;
    });
    return data
  }

  async getCountryById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.COUNTRY}/${id}`)
    return data
  }

  async storeCountry(data) {
    const { country } = await http.post(`/api/${ENV.API_ROUTES.COUNTRY}/create`, data)
    return country
  }

  async editCountryById(id, data) {
    const { country } = await http.put(`/api/${ENV.API_ROUTES.COUNTRY}/${id}`, data)
    return country
  }

  async deleteCountryById(id) {
    await http.delete(`/api/${ENV.API_ROUTES.COUNTRY}/${id}`)
  }
}

export const countryService = new CountryService()
