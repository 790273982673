import React from 'react'
import { CDBModalFooter, CDBBox } from 'cdbreact'
import europe from '../assets/europe.png'

export const Footer = () => {
  return (
    <CDBModalFooter
      style={{
        backgroundColor: '#263238',
        zIndex: 1,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '12%'
      }}
      className="shadow"
    >
      <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className="mx-auto py-4 flex-wrap"
        style={{ width: '80%' }}
      >
        <CDBBox display="flex" alignItems="center">
          <a href="/" className="d-flex align-items-center p-0 text-light">
            <img alt="logo" src="https://ocitytest.webs.upv.es/assets/dist/img/logo-ocity-black.png" width="100" />
          </a>
        </CDBBox>
        <CDBBox>
          <a href="" className="d-flex align-items-center p-0 text-light">
            Mail
          </a>
        </CDBBox>
        <CDBBox>
          <a href="/" className="d-flex align-items-center p-0 text-light">
            Home
          </a>
        </CDBBox>
        <CDBBox>
          <a
            href="https://ocitytest.webs.upv.es/assets/dist/docs/Pol%C3%ADtica%20de%20Privacidad%20O-CITY.pdf"
            className="d-flex align-items-center p-0 text-light"
          >
            PrivacyPolicy
          </a>
        </CDBBox>
        <CDBBox style={{display: 'flex', flexFlow: 'column'}}>
          <small className="ml-2 text-light">&copy; 2022 copyright O-City Project 1.0.5</small>
          <small className="ml-2 text-light">Developed by 
            </small>
            <small className="ml-2 text-light"><a href='https://ysolve.es'>Ysolve Soluciones Tecnológicas SL</a></small>
            </CDBBox>
        <CDBBox display="flex">
          <a href="/" className="d-flex align-items-center p-0 text-light">
            <img alt="logo" src={europe} width="120px" />
          </a>
        </CDBBox>
      </CDBBox>
    </CDBModalFooter>
  )
}
