import { createContext, useEffect, useState } from 'react'
import authApi from '../../services/auth.service'
import { jwt } from '../../utils/jwt'

export const AuthContext = createContext({
  isAuthenticated: null,
  accessToken: null,
  user: null,
  login: () => null,
  logout: () => null,
  hasRole: () => null,
  updateUser: () => null,
  forgotPassword: () => null,
  setNewPassword: () => null
})

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    const fetchUser = () => {
      const response = jwt.getTokens()
      const localUser = localStorage.getItem('user')
      const user = localUser ? JSON.parse(localUser) : null
      if (user) {
        setUser(user)
        setIsAuthenticated(true)
        setAccessToken(response.accessToken)
      }
    }
    fetchUser()
  }, [])

  const login = async (data) => {
    try {
      const { user, token, refresh_token } = await authApi.login(data)
      localStorage.setItem('user', JSON.stringify(user))
      jwt.saveTokens(token, refresh_token)
      setUser(user)
      setIsAuthenticated(true)
    } catch (error) {
      setIsAuthenticated(false)
      throw error
    }
  }

  const forgotPassword = async (data) => await authApi.forgot(data)

  const setNewPassword = async (data) => await authApi.newPassword(data)

  const logout = () => {
    jwt.removeTokens()
    localStorage.removeItem('user')
    setUser(null)
    setIsAuthenticated(false)
    setAccessToken(null)
  }

  const hasRole = (...roles) => {
    if (!user) return false
    return user.roles.some((x) => roles.includes(x.role_id))
  }

  const updateUser = (user) => {
    setUser(user)
    localStorage.setItem('user', JSON.stringify(user))
  }

  const data = {
    isAuthenticated,
    accessToken,
    user,
    logout,
    login,
    hasRole,
    updateUser,
    forgotPassword,
    setNewPassword
  }
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
}
