import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
// Estilos
import styles from './MapMenu.module.css'
import MenuItem from './MenuItem/MenuItem.component'
import heritageService from '../../../services/heritage.service'
import { getCategoryIcon } from '../../../utils/helpers'
import { Button, Checkbox, Input, Modal } from 'antd'
import MAGNIFIER_INDEX_ICON from '../../../assets/mapIcons/magnifier_index_icon.png'
import MAP_SEARCH_ICON from '../../../assets/mapIcons/map_search_icon.png';
import SearchCityModal from './SearchCityModal/SearchCityModal.component' // Importa el nuevo componente
import DropdownCountries from '../../DropdownCountries/DropdownCountries.component'
import DropdownCities from '../../DropdownCities/DropdownCities.component'
import DropdownStates from '../../DropdownStates/DropdownStates.component'

const MapMenu = ({ onUpdateCategories, onSearch }) => {

  const navigate = useNavigate();
  const [categories, setCategories] = useState([])
  const [modalSearch, setModalSearch] = useState(false)
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [searchData, SetSearchData] = useState({
    country: {id: null, name: null},
    state: {id: null, name: null},
    city: {id: null, name: null},
    cityNetwork: null,
    description: null,
    unescoProtected: null
  })

  const [modalSearchCity, setModalSearchCity] = useState(false)

  const toggleCategory = (category) => {

    if (categoriesSelected.includes(category)) {
      setCategoriesSelected((value) => value.filter((item) => item !== category))
      return
    }
    setCategoriesSelected((value) => [...value, category])
  }

  const closeAndReset = () => {
    setModalSearch(false)
    setCategoriesSelected([])
    onSearch?.({
      categories: [],
      unescoProtected: null,
      country: null,
      city: null,
      description: null,
      cityNetwork: null,
      reset: true
    })
    SetSearchData({
      country: {id: null, name: null},
      state: {id: null, name: null},
      city: {id: null, name: null},
      description: null,

    })
    navigate("");
  }

  const search = () => {
    onSearch?.({
      ...searchData,
      categories: categoriesSelected,
      reset: false
    })
    navigate("");
    setModalSearch(false)
  }

  useEffect(() => {
    heritageService.getHeritageFieldsTypes().then((fields) => {
      setCategories(fields)
    })
  }, [])

  useEffect(() => {
    !modalSearch && onUpdateCategories?.(categoriesSelected)
  }, [categoriesSelected])

  useEffect(() => {
    if (categoriesSelected.length === 0) setCategoriesSelected(categories.map((item) => item.id))
  }, [])

  return (
    <div id="MapMenu">
      <div className={styles.mainContainermapmenu}>
        <header>
          <h5>Filter</h5>
        </header>

        {categories.map((item) => (
          <MenuItem key={item.id} title={item.name} icon={getCategoryIcon(item.id)} setActivated={() => { toggleCategory(item.id)} }
            linkActivated={categoriesSelected.includes(item.id)} location={location.pathname}
          />))}

        <MenuItem style={{ bot: '0 !important' }} title={'Advanced Search'} icon={MAGNIFIER_INDEX_ICON} 
          setActivated={() => { setModalSearch(true) }} />
        <MenuItem style={{ bot: '0 !important' }} title={'City/Hheritage Search'} icon={MAP_SEARCH_ICON} 
          setActivated={() => { setModalSearchCity(true); }} />
      </div>

      <Modal closable={false} maskClosable={false} width={800} footer={null} title={'Advanced Search'}
        open={modalSearch} onCancel={() => setModalSearch(false)}
      >
        <div className="row">
          <div className="col-6 mb-3">
            <div className="form-group">
              <DropdownCountries value={searchData.country.id} required={false} onChange={(value,option) => {
                  SetSearchData({ ...searchData, country:{id:value,name:option.label}, state:{id:null,name:null}})
                }} />
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="form-group">
              <DropdownStates country_id={searchData.country.id} value={searchData.state.id } required={false} byCountry={false} onChange={(value, option) => {
                  SetSearchData({  ...searchData,  state:{id:value,name:option.label} ,city:{id:null,name:null}})
                }} />
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="form-group">
              <DropdownCities state_id={searchData.state.id} value={searchData.city.id } originEntity='heritage' byState={false}  required={false} 
                onChange={(value, b, option = {label: searchData.city.name}) => {SetSearchData({ ...searchData, city: {id:value, name:option.label} })}} 
                showError={false} />
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <Input name="description" id="description" onChange={(e) => SetSearchData({ ...searchData, description: e.target.value })} value={searchData.description} />
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="form-group">
              <label htmlFor="city_network">City Network</label>
              <Input name="city_network" id="city_network" onChange={(e) => SetSearchData({ ...searchData, cityNetwork: e.target.value })} value={searchData.cityNetwork} />
            </div>
          </div>
        </div>

        <label className="mb-2">Category</label>
        <div className="d-flex flex-wrap gap-2">
          <Button type="default" size="large" onClick={() => categories.map((item) => toggleCategory(item.id))}> Seleccionar todos </Button>
         <div>
          {categories.map((item) => (
            <Checkbox key={item.id} className="text-nowrap" onChange={() => toggleCategory(item.id)} checked={categoriesSelected.includes(item.id)} >
              {item.name}
            </Checkbox>
          ))}
        </div>
        </div>

        <Checkbox className="text-nowrap mt-5" onChange={(e) =>  SetSearchData({ ...searchData, unescoProtected: e.target.checked})} checked={searchData.unescoProtected} > 
          UNESCO protected
        </Checkbox>
        <div className="mt-5 text-center d-flex gap-3 justify-content-center">
          <Button type="default" size="large" onClick={() => closeAndReset()}> Clear Filters </Button>
          <Button type="default" size="large" onClick={() => { setModalSearch(false), navigate(""); }}> Close </Button>
          <Button type="primary" size="large" onClick={() => search()}> Search</Button>
        </div>
      </Modal>

      <SearchCityModal modalSearchCity={modalSearchCity} setModalSearchCity={setModalSearchCity} />
    </div>
  )
}

export default MapMenu
