import { Button, Col, Form, Input, Typography, Row } from 'antd'
import React, { useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
// import * as Yup from 'yup'

const { Text } = Typography

const UpdateItemForm = ({ inputAttributes, element }) => {
  // --------------------------------------------
  const [isAlertVisible] = useState(false)

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Required'
    }

    if (!values.surname) {
      errors.surname = 'Required'
    }

    if (!values.country) {
      errors.country = 'Required'
    }

    if (!values.city) {
      errors.city = 'Required'
    }

    if (!values.user) {
      errors.user = 'Required'
    }

    if (!values.role) {
      errors.role = 'Required'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    return errors
  }
  return (
    <Formik
      initialValues={{ ...element }}
      validate={validate}
      validateOnChange={false}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          alert(JSON.stringify(values))
          actions.setSubmitting(false)
        }, 1000)
      }}
    >
      {(formik) => (
        <Form
          labelAlign="top"
          style={{
            maxWidth: 900
          }}
          onFinish={formik.handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          {element && <h4>ID: {element.id}</h4>}
          <Row gutter={24}>
            {inputAttributes.map((attr, index) => (
              <Col span={12} key={index}>
                <Form.Item
                  key={index}
                  label={attr.name}
                  name={attr.key}
                  className={{ 'input-error': Object.prototype.hasOwnProperty.call(formik.errors, attr.key) }}
                >
                  {element ? (
                    <>
                      <Input
                        name={attr.key}
                        id={attr.key}
                        placeholder={element[attr.key]}
                        onChange={formik.handleChange}
                        defaultValue={element[attr.key]}
                      />
                      <ErrorMessage name={attr.key} />
                    </>
                  ) : (
                    <Input copyable />
                  )}
                </Form.Item>
              </Col>
            ))}
          </Row>

          <Col span={24}>
            <Form.Item wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 12, offset: 6 } }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
          {isAlertVisible && (
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16
              }}
            >
              <Text type="success">Element edited!</Text>
            </Form.Item>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default UpdateItemForm
