import { useMemo } from 'react'

export const filterHeritages = (categoriesSelected, filters, heritages) => {
  return useMemo(() => {
    let filteredHeritages = [...heritages]
    if (categoriesSelected.length > 0) {
      filteredHeritages = filteredHeritages.filter((x) => categoriesSelected.includes(x.heritage_field_id))
    }
    return filteredHeritages
  }, [categoriesSelected, filters, heritages])
}
