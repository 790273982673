import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import $ from 'jquery'
import { message } from 'antd'
import { userMessages } from '../../../utils/userMessages'
import { useTranslation } from 'react-i18next'
import { ENV } from '../../../utils/constants'
import noAvailableImage from '../../../assets/NO_AVAILABLE_IMG.png'
import styles from './WindowMarker.module.css'
import '../../../styles/App.css'
import { useNavigate } from 'react-router-dom'
import { PDFGenerator } from './PDFGenerator/PDFGenerator.component'

const WindowMarker = forwardRef(({ playContentsRef, setClickedMarkerId }, ref) => {
  const [cityInfo, setCityInfo] = useState({})
  const [visible, setVisible] = useState(false)
  const [readMore, setReadMore] = useState(false)
  const [descriptionLocalLanguage, setDescriptionLocalLanguage] = useState(false)
  const { t } = useTranslation()
  const markerRef = useRef(null)
  const navigate = useNavigate()

  // Cerrar el componente al hacer clic fuera de él solo cuando esté visible
  useEffect(() => {
    function handleClickOutside(event) {
      if (visible && markerRef.current && !markerRef.current.contains(event.target)) {
        setVisible(false)
        setClickedMarkerId(null)
        setCityInfo({})
        navigate('/')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [visible, setClickedMarkerId])

  // Función para evitar propagación de eventos al hacer clic dentro del componente
  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  function shareOnFacebook() {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${ENV.PUBLIC_URL}/city/${cityInfo.city_id}/heritage/${cityInfo.id}`
    window.open(shareUrl, '_blank', 'width=820,height=640')
  }

  function shareOnTwitter() {
    const text = encodeURIComponent('¡Mira este enlace interesante!')
    const url = encodeURIComponent(`${ENV.PUBLIC_URL}/city/${cityInfo.city_id}/heritage/${cityInfo.id}`)
    const hashtags = encodeURIComponent('turismo,o-city')
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`
    window.open(twitterShareUrl, '_blank', 'width=820,height=640')
  }

  useImperativeHandle(ref, () => ({
    setData: (item) => {
      setCityInfo(item)
      setReadMore(false)
      setDescriptionLocalLanguage(false)
      try {
        if (item.linksn_iterest_manif) item.links_interest_manif = JSON.parse(item.linksn_iterest_manif)
      } catch (error) {
        console.error(error)
      }
    },
    setVisible: (op) => {
      setVisible(op)
      $('#window-marker').css('display', 'block')
    },
    visible: () => visible
  }))

  const handleClick = () => {
    setVisible(!visible)
    setClickedMarkerId(null)
    if (!visible) {
      setTimeout(function () {
        $('#play-contents-main').css('display', 'none')
      }, 1000)
    }
  }

  function handleClickDescription() {
    setReadMore(!readMore)
  }

  const displayContents = () => {
    if (cityInfo?.heritageContent) {
      const dataVerifies = cityInfo.heritageContent.filter(item => item.is_verified === true);
      cityInfo.heritageContent = dataVerifies
      if (cityInfo.heritageContent?.length > 0) {
        playContentsRef.current.setData(cityInfo)
        if (!playContentsRef.current.visible()) {
          playContentsRef.current.setVisible(true)
        }
        setVisible(!visible)
      } else {
        message.error(userMessages.detailsNotFound)
      }
    }
  }
  const isCity = () => {
    return cityInfo.extended_local_heritage_description ? false : true
  }
  const getDescription = () => {
    if (isCity()) {
      return descriptionLocalLanguage ? cityInfo.description_local : cityInfo.description
    }
    if (readMore) {
      return descriptionLocalLanguage
        ? cityInfo.extended_local_heritage_description
        : cityInfo.extended_heritage_description
    } else {
      return descriptionLocalLanguage ? cityInfo.short_local_heritage_description : cityInfo.short_heritage_description
    }
  }
  // TODO: refacotr  use it in utils
  const handleErrorImage = (event) => {
    event.target.src = noAvailableImage
  }

  const copyToClipboard = () => {
    const url = isCity() ? `${ENV.PUBLIC_URL}/city/${cityInfo?.city?.id}` : `${ENV.PUBLIC_URL}/city/${cityInfo?.city?.id}/heritage/${cityInfo?.id}`

    navigator.clipboard
      .writeText(url)
      .then(() => {
        message.success('Link copiado')
      })
      .catch((err) => {
        console.error('Error al copiar el link: ', err)
      })
  }

  return (
    <div
      ref={markerRef}
      id={'window-marker'}
      onClick={stopPropagation} // Evita que el clic dentro del componente afecte otros elementos
      className={[styles.mainContainer, visible ? 'fadeIn' : 'fadeOut'].join(' ')}
    >
      <header>
        <h5 className="text-uppercase">{cityInfo?.name  ? (cityInfo?.name + ` (${cityInfo?.city?.name})`) : cityInfo.city?.name}</h5>
        <i style={{ cursor: 'pointer' }} className="bi bi-x" onClick={() => handleClick()}></i>
      </header>
      <img src={cityInfo?.image} alt={`image ${cityInfo?.name}`} onError={handleErrorImage} />
      <div
        className={`d-flex align-items-center px-4 mt-2 ${cityInfo.image_copyright ? 'justify-content-between' : 'justify-content-end'}`}
      >
        {cityInfo.image_copyright && (
          <p className="m-0 text-start">
            Copyright: <span className="d-block">{cityInfo.image_copyright}</span>
          </p>
        )}

        <button
          className={styles.btnPlayContent}
          disabled={!cityInfo?.heritageContent || cityInfo.heritageContent.length <= 0}
          onClick={displayContents}
        >
          {t('view_content_button')}
        </button>
      </div>

      <div className={`${styles.infoCity} px-4`}>
        <p>{getDescription()}</p>
      </div>
      <div className={styles.buttonsParagraph}>
        {cityInfo.extended_heritage_description || cityInfo.extended_heritage_description_local ? (
          <button id="btnReadMore" onClick={handleClickDescription}>
            {readMore ? t('read_less') : t('read_more')}
          </button>
        ) : (
          <></>
        )}
        <button id="btnChangeLocalLanguage" onClick={() => setDescriptionLocalLanguage(!descriptionLocalLanguage)}>
          {descriptionLocalLanguage ? t('change_description_english') : t('change_description_local_lenguage')}
        </button>
      </div>

      {cityInfo.links?.length > 0 && <h6 className="text-start ps-4">{t('interest_links')} </h6>}

      <ul className="list-group">
        {cityInfo.links?.map((link) => {
          return (
            <li key={link.id} className="text-start ps-4">
              <a href={link.url} target={'_blank'} rel="noreferrer">
                {link.name}
              </a>
            </li>
          )
        })}
      </ul>

      <div className={styles.shareBtnContainer}>
        <i className="bi bi-facebook" style={{ cursor: 'pointer' }} onClick={shareOnFacebook} key={`facebook-link`} title="Share with Facebook"></i>
        <i className="bi bi-twitter" style={{ cursor: 'pointer' }} onClick={shareOnTwitter} key={`twitter-link`}   title="Share with X (Twitter)"></i>
        <i className="bi bi-clipboard" style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={copyToClipboard} title="Copy link" ></i>
        <div>
          <PDFGenerator cityInfo={cityInfo} descriptionLocalLanguage={descriptionLocalLanguage} />
          {cityInfo.extended_heritage_description ? (
            <>
              <i
                className="bi bi-link-45deg"
                onClick={() => {
                  window.open(
                    `${ENV.EXTERNAL_OCITY_WEB_PAGES}/country/${cityInfo.country_id}/state/${cityInfo.state_id}/city/${cityInfo.city_id}/heritage/${cityInfo.id}`,
                    '_blank'
                  )
                }}
              ></i>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
})

export default WindowMarker
